import { store } from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class OpeningStockService {

    getDataForOpeningStockCreation() {
        const url = 'api/purchase/create'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }
    getOpeningStockDataById(id) {
        const url = `api/stock/opening-stock/edit/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getPurchaseItemsByBranch(id) {
        const url = `api/stock/opening-stock/branch/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getItemsBySearchKeyword(params) {
        const url = `api/purchase/items/search`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    getSuppliersBySearchKeyword(keyword) {
        let params = {
            'page-index': 15,
            'searched': keyword,
            'offset': 0
        }
        const url = `api/supplier/filter`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    storeOpeningStockData(purchaseData) {
        const url = `api/stock/opening-stock`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url, purchaseData, headers);
    }
    updateOpeningStockData(purchaseData,id) {   
        const url = `api/stock/opening-stock/update/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, purchaseData, headers);
    }

}

export default new OpeningStockService();