<template>
  <div class="col-md-12">
    <div
      aria-hidden="true"
      class="modal fade modal_cust show"
      role="dialog"
      tabindex="-1"
      id="purchaseItemList"
      ref="purchaseItemModal"
      v-if="modalId == 'purchaseItemList'"
    >
      <div
        class="
          modal-dialog
          listing-popup-place
          item-container-place
          modal_ac_head
        "
        role="document"
      >
        <div class="modal-content search-table-container">
          <div class="form-group has-search mb-0">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Search"
              v-model="itemSearched"
              @input="searchItem"
              ref="itemSearch"
            />
          </div>
          <table class="table table1">
            <thead>
              <tr>
                <th scope class="wd-5p table-start-item">S.N.</th>
                <th scope class="wd-15p">Item Id</th>
                <th scope class="wd-25p">Item Name</th>
                <th scope class="wd-15p">Category</th>
              </tr>
            </thead>
            <tbody v-if="!itemLoading && itemToBePurchased.length > 0">
              <tr
                v-for="(item, index) in itemToBePurchased"
                :key="`${index}${item.name}`"
                @click="selectItem(item.id)"
                data-dismiss="modal"
              >
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ item.item_id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.item_group.title }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="!itemLoading && itemToBePurchased.length == 0">
              <tr>
                <td colspan="11" style="text-align: center">
                  No records found.
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="itemLoading || itemSearchLoading">
              <tr>
                <td colspan="11" style="text-align: center">Loading.....</td>
              </tr>
            </tbody>
            <tbody v-else-if="error">
              Error Fetching data from the server.
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Service";
import _ from "lodash";

export default {
  data() {
    return {
      isActive: "all",
      itemSearched: "",
      itemStockable: false,
      selectedItemGroup: "",
      itemLoading: false,
      errorItemSearch: false,
      items: {
        unit_type: null,
        vat_applicable: null,
        item_id: null,
        name: null,
        available: null,
        quantity: null,
        purchase_rate: null,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        purchase_discount_percentage: null,
        purchase_discount_amount: null,
        total: null,
        barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters(["modalWithMode", "modalId", "dataLists6"]),
    ...mapGetters("purchase", [
      "purchaseItem",
      "branchId",
      "itemGroups",
      "itemSearchLoading",
      "itemToBePurchased",
      "searchAutoFocus",
    ]),
  },
  mounted() {},
  methods: {
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },

    //when  the item is selected from Item List modal
    selectItem(id) {
      this.itemStockable = true;
      let details = this.itemToBePurchased.find(function (data) {
        return data.id == id;
      });
      //Generating the barcode of the product while making a purchase
      let newBarcode = Date.now() + Math.floor(Math.random() * 10);
      details["barcode"] = newBarcode.toString();
      this.$store.commit("purchase/setPurchaseItem", details);
      this.$emit("clicked", details);
    },

    selectItemGroup(itmGroup, index) {
      if (itmGroup) {
        this.selectedItemGroup = itmGroup.slug;
      } else {
        this.selectedItemGroup = "";
      }
      this.isActive = index;
      const params = {
        keyword: this.itemSearched,
        "item-group": this.selectedItemGroup,
      };
      this.ajaxSearchFilter(params);
    },

    //search item with searched keyword or the item group
    searchItem: _.debounce(function () {
      this.itemLoading = true;
      const params = {
        keyword: this.itemSearched,
        "item-group": this.selectedItemGroup,
      };
      this.ajaxSearchFilter(params);
    }, 600),

    ajaxSearchFilter(params) {
      Services.Purchase.getItemsBySearchKeyword(params)
        .then((response) => {
          let value = response.data.data.items;
          this.$store.commit("purchase/setItemToBePurchased", value);
        })
        .catch((error) => {
          this.errorItemSearch = true;
          console.log(error);
        })
        .finally(() => {
          this.itemLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.active {
  color: #1b52bf !important;
  background: white !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>